import React from "react";

import styles from "./product-preview-list.module.css";
import ProductPreview from "./product-preview";

function ProductPreviewGrid(props) {
  return (
    <div className={styles.root}>
      <ul className={styles.grid}>
        {props.nodes &&
          props.nodes.map((node) => (
            <li className={styles.listItem} key={node.id}>
            <ProductPreview {...node} isInList />
            </li>
          ))}
      </ul>
    </div>
  );
}

ProductPreviewGrid.defaultProps = {
  title: "",
  nodes: [],
  browseMoreHref: "",
};

export default ProductPreviewGrid;
