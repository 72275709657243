import React from "react";

import styles from "./testimonial-list.module.css";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";

function TestimonialList(props) {
  if (props.nodes.length > 0) {
    return (
      <div className={styles.root}>
        <ul className={styles.testimonialGrid}>
          {props.nodes &&
            props.nodes.map((node) => (
              <div className={styles.testimonialItem}>
                <li key={node.id} className={styles.quoteAndImage}>
                  {node.image && node.image.asset && (
                    <img
                      width="120px"
                      height="120px"
                      src={imageUrlFor(buildImageObj(node.image))
                        .width(120)
                        .height(120)
                        .auto("format")
                        .url()}
                      alt={node.image.alt}
                      className={styles.image}
                    />
                  )}
                  <blockquote>
                    <p className={styles.quotation}>{`${node.quote}"`}</p>
                  </blockquote>
                </li>
                <p>
                  <strong>- {node.clientName}</strong>
                </p>
              </div>
            ))}
        </ul>
      </div>
    );
  } else {
    return null;
  }
}

TestimonialList.defaultProps = {
  title: "",
  nodes: [],
  browseMoreHref: "",
};

export default TestimonialList;
