import {Link} from 'gatsby'
import React from 'react'
import {buildImageObj, cn, getProductUrl} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'

import styles from './product-preview.module.css'
import {responsiveTitle3} from './typography.module.css'

function ProductPreview (props) {
  return (
    <Link
      className={props.isInList ? styles.inList : styles.inGrid}
      to={getProductUrl(props.slug.current)}
    >
      <div className={cn(styles.iconCircle, styles[props.color])}>
        {props.icon && props.icon.asset && (
          <img
            src={imageUrlFor(buildImageObj(props.icon))
              .width(80)
              .height(80)
              .auto('format')
              .url()}
            alt={props.icon.alt}
          />
        )}
      </div>
      <div className={styles.text}>
        <h3 className={cn(responsiveTitle3, styles.title)}>{props.name}</h3>
        {props.teaserText && (
          <div className={styles.excerpt}>
            {props.teaserText}
          </div>
        )}
      </div>
    </Link>
  )
}

export default ProductPreview
