import React from "react";
import { graphql } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture, 
} from "../lib/helpers";
import BlogPostPreviewList from "../components/blog-post-preview-list";
import ProductPreviewList from "../components/product-preview-list";
import Container from "../components/container";
import FullWidthContainer from "../components/full-width-container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import TestimonialList from "../components/testimonial-list";
import { FaUserFriends, FaGlobeAfrica, FaQuestionCircle, FaFirstAid } from "react-icons/fa";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      mainImage {
        ...SanityImage
        alt
      }
      uspLinkText
      languagesLinkText
      contactUsLinkText
      helpLinkText
    }
    posts: allSanityPost(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          excerpt
          slug {
            current
          }
        }
      }
    }
    products: allSanityProduct(filter: { showOnFrontPage: {eq: true } }) {
      edges {
        node {
          id
          icon {
            ...SanityImage
            alt
          }
          color
          name
          teaserText
          showOnFrontPage
          excerpt
          slug {
            current
          }
        }
      }
    }
    testimonials: allSanityTestimonial(limit: 3) {
      edges {
        node {
          id
          image {
            ...SanityImage
            alt
          }
          clientName
          quote
          link
        }
      }
    }
  }
`;

const IndexPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];
  const productNodes = (data || {}).products
    ? mapEdgesToNodes(data.products)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];
  const testimonialNodes = (data || {}).testimonials ? mapEdgesToNodes(data.testimonials) : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
      <SEO title={site.title} description={"site.description"} />
      <div className="frontPageImage">
        {site.mainImage && site.mainImage.asset && (
          <img
            src={imageUrlFor(buildImageObj(site.mainImage)).width(1920).auto("format").url()}
            alt={site.mainImage.alt}
          />
        )}
      </div>
      <div className="iconButtonBox left">
        <a href="/artikler/passer-vi-for-deg/">
          <div className="iconButton">
            <i>
              <FaUserFriends />
            </i>
            <span>{site.uspLinkText}</span>
          </div>
        </a>
        <a href="/artikler/vi-snakker-mange-sprak/">
          <div className="iconButton">
            <i>
              <FaGlobeAfrica />
            </i>
            <span>{site.languagesLinkText}</span>
          </div>
        </a>
        <a href="/kontakt/">
          <div className="iconButton">
            <i>
              <FaQuestionCircle />
            </i>
            <span>{site.contactUsLinkText}</span>
          </div>
        </a>
      </div>
      <div className="iconButtonBox right">
        <a href="/tjenester/hjelp/">
          <div className="iconButton help">
            <i>
              <FaFirstAid />
            </i>
            <span>{site.helpLinkText}</span>
          </div>
        </a>
      </div>
      <Container>
        <h1 hidden>{site.title}</h1>
        {productNodes && (
          <ProductPreviewList
            title="Produktpakker"
            nodes={productNodes}
            browseMoreHref="/tjenester/"
          />
        )}

        {postNodes && (
          <BlogPostPreviewList title="Artikler" nodes={postNodes} browseMoreHref="/artikler/" />
        )}
        {testimonialNodes && testimonialNodes.length > 0 && <h2>Kundeomtale</h2>}
      </Container>

      {testimonialNodes && testimonialNodes.length > 0 && (
        <>
          <FullWidthContainer>
            <TestimonialList nodes={testimonialNodes} />
          </FullWidthContainer>
        </>
      )}
    </Layout>
  );
};

export default IndexPage;
